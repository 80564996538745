<template>
    <span>
        <button class="button button--icon" @click="offcanvasShow = true"><svg class="icon" width="22" height="22"><use xlink:href="#edit" /></svg></button>
            <UiOffCanvas :offcanvas="offcanvasShow" :offcanvasShow.sync="offcanvasShow">

                  <template v-slot:header>
                      <h4>Modifica {{ classe.nome }} </h4>
                  </template>

                    <template v-slot:form>
                      <template v-if="!loading">
                      <div class="form">
                        <ErrorsAlert/>
                        <div class="field-group flex">
                          <label>Nome classe</label>
                          <input type="text" name="nome" v-model="nome">
                        </div>

                        <div class="field-group flex">
                          <label>Scuola <span class="required">*</span></label>
                          <multiselect v-model="scuola"
                             :options="getScuole"
                             :show-labels="true"
                             :custom-label="nomeScuolaCompleto"
                             label="nome"
                             track-by="nome"
                             placeholder="Scegli scuola"></multiselect>
                         </div>

                        <div class="field-group flex">
                          <div class="field col col-12">
                             <label>Percorso <span class="required">*</span></label>
                                <multiselect v-model="percorso"
                                    :options="getPercorsi"
                                    :show-labels="true"
                                    label="titolo"
                                    track-by="titolo"
                                    placeholder="Scegli percorso"></multiselect>
                          </div>
                        </div>

                        <div class="field-group flex">
                          <div class="field col col-12">
                             <label>Insegnante</label>
                                <multiselect v-model="insegnanti"
                                    :options="getInsegnanti"
                                    :multiple="false"
                                    :show-labels="true"
                                    :custom-label="nomeUserCompleto"
                                    :allow-empty="true"
                                    label="nome"
                                    track-by="nome"
                                    placeholder="Scegli insegnante"></multiselect>
                          </div>
                        </div>


                         <div class="field-group flex">
                          <div class="field col col-12">
                             <label>Tutor</label>
                                <multiselect v-model="educatori"
                                    :options="getEducatori"
                                    :multiple="false"
                                    :show-labels="true"
                                    :allow-empty="true"
                                    :custom-label="nomeUserCompleto"
                                    label="nome"
                                    track-by="nome"
                                    placeholder="Scegli tutor"></multiselect>
                          </div>
                        </div>

                        <div class="form--action">
                        <button class="button--delete" @click.prevent="modalShow = true" :disabled="disableSaveButton">Elimina classe</button>
                        <button class="button button--small m-l-auto" @click.prevent="aggiornaClasse(classe)" :disabled="disableSaveButton">Salva</button>
                        </div>

                      </div>

                      <div class="offcanvas--content--highlight" v-if="classe.user">
                            <p>La password di accesso dell'utente classe <strong> {{ classe.user.username }} </strong> è {{ pass }}</p>
                      </div>

                      </template>
                      <template v-else>
                          <UiPreloader/>
                      </template>

                    </template>

            </UiOffCanvas>

             <UiModal :modal="modalShow" :modalShow.sync="modalShow">
                <template v-slot:header>
                    <h4 class="modal--title">Sicuri di voler eliminare la classe {{ classe.nome }}?</h4>
                </template>

                <template v-slot:form>
                        <div class="form">
                        <ErrorsAlert />
                        <p>Digita <strong>ELIMINA</strong> nel campo sottostante per eliminare la classe <strong>{{ classe.nome }}</strong>. <br/>Verranno eliminate tutti i contenuti associati</p>
                        <div class="field-group flex">
                            <div class="field col col-12">
                                <input type="text" v-model="confermaEliminazione">
                            </div>
                        </div>
                        <div class="form--action">
                        <button class="button button--annulla" @click="modalShow = false">Annulla</button>     
                        <button class="button button--conferma" @click.prevent="eliminaClasse(classe)" :disabled="confermaEliminazione != 'ELIMINA'">Conferma ed elimina</button>
                        </div>
                        </div>
                </template>

            </UiModal>

    </span>
</template>
<script>

import SuccessAlert from '@/components/SuccessAlert.vue'
import ErrorsAlert from '@/components/ErrorsAlert.vue'

import { mapGetters, mapState, mapActions } from 'vuex'

export default {
    name: 'ModificaClasse',

    data() {
        return {
            disableSaveButton: false,
            offcanvasShow: false,
            nome: this.classe.nome,
            scuola: this.classe.scuola,
            percorso: this.classe.percorso,
            insegnanti: this.classe.insegnanti,
            educatori: this.classe.educatori,
            loading: false,
            modalShow: false,
            confermaEliminazione: ''
        }
    },

    props: {
        classe: Object
    },

    components: {
        ErrorsAlert,
        SuccessAlert
    },
    

    computed: {
        ...mapGetters('scuole',[
                'getScuole'
            ]),

        ...mapGetters('percorsi',[
                'getPercorsi'
        ]),

        ...mapGetters('istituti',[
                'getIstituto'
        ]),

         ...mapGetters('utenti',[
                'getEducatori',
                'getInsegnanti',
        ]),

        pass() {
            return atob(this.classe.user.password)
        }

    },


    watch: {
        /*
        offcanvasShow(){
            if (this.offcanvasShow == true){
                this.$store.dispatch('istituti/fetchIstituto', {
                 id: this.classe.scuola.istituto_id}
                )

            }
        }
        */
    },

    methods: {

        ...mapActions('classi',[
            'updateClasse',
            'deleteClasse'
        ]),
        
        nomeScuolaCompleto ({ nome, citta, istituto}) {
            return `${nome} - ${citta} - ${istituto.nome}`
       },

       nomeUserCompleto({ nome, email }) {
            return `${nome} (${email})`
        },

        aggiornaClasse () {

            this.disableSaveButton = true
            this.loading = true
            this.updateClasse({
                id:  this.classe.id,
                nome: this.nome,
                scuola_id: this.scuola.id,
                percorso_id: this.percorso.id,
                insegnante_id: this.insegnanti ? this.insegnanti.id : null,
                educatore_id: this.educatori ? this.educatori.id : null
            }).then((response) => {
                 this.offcanvasShow = false
                 this.loading = false
                 this.disableSaveButton = false
                 this.$store.dispatch('classi/fetchClassi')
            }).catch(e => {
                this.$store.state.success = null;
                this.loading = false
                this.$store.state.errors = e.response.data;
            })
        },

        eliminaClasse (classe) {
            this.disableSaveButton = true
            this.deleteClasse(classe).then((response) => {
                this.offcanvasShow = false
                this.disableSaveButton = false
                this.$store.dispatch('classi/fetchClassi')
            }).catch(error => {
                console.log('Errore:'+error)
            })
        },

    }


}
</script>