<template>
  <div>
      <header>
          <h1 class="header--title">Classi <span v-if="getClassi && getClassi.length > 0" class="badge badge--dark">{{ getClassi.length }}</span></h1>
          <div class="header--right">

            <div class="searchContainer">
              <input type="text" placeholder="Cerca" @keyup="cercaClassi()" v-model="key">
              <button @click="resetCercaClassi()" v-if="key">x</button>
            </div>

          <NuovaClasse />
          </div>

      </header>
        
        <table class="table" v-if="this.$store.state.loading == false">

            <tr class="table--header">
                  <th class="cell">
                    Nome
                  </th>
                  
                  <th class="cell">
                    Scuola
                  </th>
                  <th class="cell">
                    Percorso
                  </th>
                  <th class="cell">
                    Insegnante
                  </th>
                  <th class="cell">
                    Educatori
                  </th>
                  <th class="cell">
                    Utente
                  </th>
                  <th class="cell">
                    Messaggi
                  </th>
                  <th class="cell">
                    Quiz
                  </th>
                  <th class="cell">
                    Azioni
                  </th>
                  <th class="cell">
                    
                  </th>
            </tr>

            <tr v-for="classe in getClassi" :key="classe.id" class="row">
                <td class="cell">
                <strong>{{ classe.nome }}</strong>
                </td>

                <td class="cell">
                <span v-if="classe.scuola">{{ classe.scuola.nome }} {{ classe.scuola.comune }}</span>
                </td>

                <td class="cell">
                  <span v-if="classe.percorso" :class="['badge',classe.percorso.categoria]">
                  {{ classe.percorso.titolo }}
                  </span>
                </td>

                <td v-if="classe.insegnanti" class="cell">
                  <span v-for="insegnante in classe.insegnanti" :key="insegnante.id">
                    {{ insegnante.nome }}
                  </span>
                </td>

                <td v-if="classe.educatori" class="cell">
                  <span v-for="educatore in classe.educatori" :key="educatore.id">
                    {{ educatore.nome }}
                  </span>
                </td>

                <td class="cell">
                  <span v-if="classe.user">
                      {{ classe.user.username }}
                  </span>
                </td>

                <td class="cell">
                  <span class="badge badge--error" v-if="classe.messagginonletti && classe.messagginonletti != '0'">
                    {{ classe.messagginonletti }}
                  </span>
                </td>

                 <th class="cell">
                    <span class="badge badge--success" v-if="classe.quiz == 1">Attivo</span>
                    <span class="badge badge--ligthgray" v-else>Non attivo</span>
                 </th>
                
                <td class="cell">
                <ModificaClasse :classe="classe"/>
                </td>
                <td class="cell">
                <router-link :to="{ name: 'Classe', params: { classeId: classe.id }}" class="button button--outline button--extrasmall">Entra</router-link>
                </td>
              
            </tr>
        </table>
        <div v-else>
            <UiPreloader/>
        </div>

  </div>
  
</template>

<script>
// @ is an alias to /src
import NuovaClasse from '@/components/editor/NuovaClasse.vue'
import ModificaClasse from '@/components/editor/ModificaClasse.vue'
import { mapGetters, mapState, mapActions } from 'vuex'
export default {
  name: 'EditorClassi',

  data: () => {
    return {
      key: ''
     //classi: []
    }
  },
  
  components: {
      NuovaClasse,
      ModificaClasse
  },

  computed: {
    ...mapGetters([
      'isLogged',
      'isAdmin',
      'isEducatore',
      'isInsegnante',
      'isClasse',
      'user'
    ]),

    ...mapGetters('classi',[
        'getClassi',
        'getClassiSearch'
     ]),

  },

  methods: {
    ...mapActions('classi',[
        'fetchClassi',
    ]),

    cercaClassi() {
      this.tipo = '',
      this.key = event.target.value,
      this.getClassiSearch(event.target.value)
    },

    resetCercaClassi() {
      this.tipo = '',
      this.key = '',
      this.getClassiSearch('')
    },

  },

  beforeCreate() {
    this.$nextTick().then(() => document.body.className = '')
  },


  mounted() {

  }

}
</script>
