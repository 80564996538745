<template>
    <span>
    <button class="button button--small" @click="modalShow = true">Nuova classe</button>
          <UiModal :modal="modalShow" :modalShow.sync="modalShow" :fullScreen.sync="modalFullScreen">
                    <template v-slot:header>
                      <h4 class="modal--title">Nuova classe</h4>
                    </template>

                    <template v-slot:content>
                      <template v-if="!loading">
                        <div class="form">
                          <ErrorsAlert />
                          
                          <div class="field-group flex">
                            <div class="field col col-6">
                              <label>Classe <span class="required">*</span></label>
                              <select type="text" name="classe" v-model="classe" required>
                                <option value="1">1ª</option>
                                <option value="2">2ª</option>
                                <option value="3">3ª</option>
                                <option value="4">4ª</option>
                                <option value="5">5ª</option>
                              </select>
                            </div>

                            <div class="field col col-6">
                              <label>Sezione <span class="required">*</span></label>
                              <input type="text" name="sezione" v-model="sezione" required>
                            </div>
                          </div>

                          <div class="field-group flex">
                            <div class="field col col-12">
                              <label>Scuola <span class="required">*</span></label>
                              <multiselect v-model="scuola"
                              :options="getScuole"
                              :show-labels="true"
                              :custom-label="nomeScuolaCompleto"
                              label="nome"
                              track-by="nome"
                              placeholder="Scegli scuola"></multiselect>
                            </div>
                          </div>

                          <div class="field-group flex">
                            <div class="field col col-12">
                              <label>Percorso</label>
                              <multiselect v-model="percorso"
                              :options="getPercorsi"
                              :show-labels="true"
                              label="titolo"
                              track-by="titolo"
                              placeholder="Scegli percorso"></multiselect>
                            </div>
                          </div>

                          <div class="field-group flex">
                            <div class="field col col-12">
                              <label>Insegnante</label>
                              <multiselect v-model="insegnanti"
                              :options="getInsegnanti"
                              :multiple="false"
                              :show-labels="true"
                              :custom-label="nomeUserCompleto"
                              label="nome"
                              track-by="nome"
                              placeholder="Scegli insegnanti"></multiselect>
                            </div>
                          </div>

                          <div class="field-group flex">
                            <div class="field col col-12">
                              <label>Tutor</label>
                              <multiselect v-model="educatori"
                              :options="getEducatori"
                              :multiple="false"
                              :show-labels="true"
                              :custom-label="nomeUserCompleto"
                              label="nome"
                              track-by="nome"
                              placeholder="Scegli educatori"></multiselect>
                            </div>
                          </div>

                        <div class="form--action">
                          <button class="button button--annulla" @click="modalShow = false">Annulla</button>
                          <button class="button button--conferma" @click.prevent="createClasse" :disabled="disableSaveButton">Salva</button>
                        </div>

                        </div>

                       
                      </template>
                      <template v-else>
                          <UiPreloader/>
                      </template>
                    </template>
                 
          </UiModal>
    </span>
</template>
<script>

import ErrorsAlert from '@/components/ErrorsAlert.vue'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
    name: 'NuovaClasse',

    data() {
        return {
            classe: '',
            sezione: '',
            scuola: '',
            percorso: '',
            educatori: '',
            insegnanti: '',
            modalShow: false,
            modalFullScreen: false,
            disableSaveButton: false,
            loading: false
        }
    },

    props: {

    },


    watch: {
    // autofocus sull'input
    modalShow(val){

        if (val == true){
            // faccio focus sul primo campo
            this.$store.state.errors = null
            this.disableSaveButton = false
            
        } else {
            // svuoto i campi quando si chiude la modal
            this.classe = '',
            this.sezione = '',
            this.scuola = '',
            this.educatori = '',
            this.insegnanti = '',
            this.percorso = ''
        }
    }


    },

    components: {
        ErrorsAlert
    },

    computed: {

        nome() {
          return this.classe+this.sezione
        },

        ...mapGetters('scuole',[
                'getScuole'
        ]),

        ...mapGetters('percorsi',[
                'getPercorsi'
        ]),

         ...mapGetters('utenti',[
                'getEducatori',
                'getInsegnanti'
        ])

    },

    methods: {
    ...mapActions('classi',[
        'addClasse'
    ]),

    nomeScuolaCompleto ({ nome, citta, istituto }) {
      return `${nome} - ${citta}, ${istituto.nome}`
    },

    nomeUserCompleto({ nome, email }) {
      return `${nome} (${email})`
    },

    createClasse() {
        this.disableSaveButton = true
        this.loading = true
        /*
        if(this.insegnanti) {
          const ins = this.insegnanti.map(x => x.id);
        } else {
          const ins = ''
        }

        if(this.educatori) {
           const edu = this.educatori.map(x => x.id);
        } else {
          const edu = ''
        }
        */

        this.addClasse({
            nome: this.nome,
            scuola_id: this.scuola.id,
            percorso_id: this.percorso.id,
            insegnante_id: this.insegnanti.id,
            educatore_id: this.educatori.id,
        }).then((response) => {
            this.modalShow = false
            this.loading = false
            this.disableSaveButton = false
            this.$store.dispatch('classi/fetchClassi')
        }).catch(e => {
            this.$store.state.success = null
            this.disableSaveButton = false
            this.loading = false
            this.$store.state.errors = e.response.data;
        })
     }

    },


    beforeMount() {
        this.$store.dispatch('scuole/fetchScuole')
        this.$store.dispatch('percorsi/fetchPercorsi')
        this.$store.dispatch('utenti/fetchUtenti')
    },

    beforeDestroy() {
        this.$store.state.scuola = null;
    }
}
</script>